export default {
    data() {
        return {
            homeUrl: {},
        };
    },
    watch: {
        authUser: {
            immediate: true,
            handler(authUser) {
                this.homeUrl =
                    Object.keys(authUser).length > 0
                        ? this.$utils.menuUrl(authUser.homeUrl)
                        : {};
            },
        },
    },
};
