<template>
    <div>
        <psi-page-title
            :title="page.title"
            :icon="page.icon"
            :breadcrumbs="breadcrumbs"
        ></psi-page-title>
        <!-- Content always starts with a v-card  -->
        <v-card class="mt-4">
            <v-card-text>
                <application-list-wrapper
                    title="Applications"
                ></application-list-wrapper>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import HomeLink from "@/mixins/homelink";
export default {
    name: "application-history",
    mixins: [HomeLink],
    components: {
        ApplicationListWrapper: () =>
            import(
                "@components/application/components/Application/History/ApplicationListWrapper.vue"
            ),
    },
    data() {
        return {
            page: {
                title: this.$t("applications.application-history"),
                icon: "mdi-clipboard-search",
            },
        };
    },
    computed: {
        breadcrumbs() {
            return [
                Object.assign(
                    {
                        text: this.$t("applications.home"),
                        disabled: false,
                    },
                    this.homeUrl
                ),
                {
                    text: this.$t("applications.applications"),
                    disabled: true,
                    to: {
                        name: "Applications",
                    },
                },
            ];
        },
    },
    mounted() {},
};
</script>

<style scoped>
</style>